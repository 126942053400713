import { Col, Container, Row } from "react-bootstrap";
import { Ponente } from "../components/Ponente";
import { Thanks } from "../components/Thanks";
import { Title } from "../components/Title";

export const HomeThanks = () => {
  return (
    <>
        <Title />
        <Container>
        <Row>
            <Col md={6} className="offset-md-3">
                <Ponente/>
                <Thanks/>
            </Col>
        </Row>
        </Container>
    </>
  )
}
