import React from "react";


export const Footer = () => {
  return (
    <>
      <footer>
        <nav className="white">
        
          <a
            href="https://s3-eu-south-2.ionoscloud.com/cursos/nhco-registro/assets/Politica.pdf"
            className="mx-2 nav-links"
            target="_blank"
            rel="noreferrer"
          >
            Política de privacidad
          </a>
          |{" "}
          <a
            href="https://s3-eu-south-2.ionoscloud.com/cursos/nhco-registro/assets/Condiciones.pdf"
            className="mx-2 nav-links"
            target="_blank"
            rel="noreferrer"
          >
            Condiciones de uso
          </a>{" "}
       
        </nav>
        <img src="/img/nhco-foot.svg" alt="nhco" className="logo__foot" />
      </footer>
      <section className="foot_mayo py-3">
        <img src="/img/mayo.svg" alt="grupo mayo" className="mb-2" />
        <p>Copyright 2023 - © Grupo Mayo, S.A.U.</p>
      </section>
    </>
  );
};
