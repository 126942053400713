import React from "react";

export const Ponente = () => {
  return (
    <div className="d-flex align-items-start mt-4 mx-3">
      <img src="/img/asun.png" alt="Asun Arias" />
      <div className="ms-3">
        <h3>
          Ponente: <span className="rosa">ASUN ARIAS</span>
        </h3>
        <p>
          Farmacéutica especialista en GxC Formación de equipos de alto
          rendimiento
        </p>
      </div>
    </div>
  );
};
