import ReactLoading from "react-loading";
import React from "react";

export const Loading = ({ type, color }) => {
  return (
    <ReactLoading
      type={"spinningBubbles"}
      color={"#A7261A"}
      height={"10%"}
      width={"10%"}
    />
  );
};
