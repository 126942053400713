import { useForm } from "../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Loading } from "./Loading";
import { BoxTopRegister } from "./BoxTopRegister";

const init = {
  name: "",
  surname_1: "",
  surname_2: "",
  dni: "",
  email: "",
  company: "",
  postcode: "",
  is_client: "",
  accepts: "",
  accepts_mayo: "",
  accepts_chiesi: "",
};

export const FormTemplate = () => {
  const { data, handleChange, handleCkeckChange, handleOption, post } =
    useForm(init);

  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [succes, setSucces] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      await post('https://devnhcomujerenelclimaterio.grupomayo.com/api/register', data); 
      navigate("/thanks");
    } catch (err) {
      if (err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
      if (err.response === 500) {
        setSucces(false);
      }
    } finally {
      setLoader(false);
    }
  };

  return (
    <section>
      <div className="box__register">
        <BoxTopRegister />

        <section className="p-5">
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
              <input
                type="text"
                onChange={handleChange}
                className="form-control"
                id="name"
                name="name"
                placeholder="Nombre"
                value={data.name}
                required
              />
              <label htmlFor="floatingInput">Nombre *</label>
              {errors["name"] && <p className="invalid">{errors["name"][0]}</p>}
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                onChange={handleChange}
                className="form-control"
                id="surname"
                name="surname_1"
                placeholder="Primer apellido"
                value={data.surname_1}
                required
              />
              <label htmlFor="floatingInput">Primer apellido *</label>
              {errors["surname_1"] && (
                <p className="invalid">{errors["surname_1"][0]}</p>
              )}
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                onChange={handleChange}
                className="form-control"
                id="surname_2"
                name="surname_2"
                placeholder="Segundo apellido"
                value={data.surname_2}
              />
              <label htmlFor="surname_2">Segundo apellido</label>
              {errors["surname_2"] && (
                <p className="invalid">{errors["surname_2"][0]}</p>
              )}
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                onChange={handleChange}
                className="form-control"
                id="dni"
                name="dni"
                placeholder="DNI"
                value={data.dni}
                required
              />
              <label htmlFor="email">DNI*</label>
              {errors["email"] && <p className="invalid">{errors["dni"][0]}</p>}
            </div>

            <div className="form-floating mb-3">
              <input
                type="email"
                onChange={handleChange}
                className="form-control"
                id="email"
                name="email"
                placeholder="Email"
                value={data.email}
                required
              />
              <label htmlFor="email">Email *</label>
              {errors["email"] && (
                <p className="invalid">{errors["email"][0]}</p>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                onChange={handleChange}
                className="form-control"
                id="company"
                name="company"
                placeholder="Nombre Farmacia"
                value={data.company}
                required
              />
              <label htmlFor="farmacia">Nombre Farmacia*</label>
              {errors["company"] && (
                <p className="invalid">{errors["company"][0]}</p>
              )}
            </div>

            <div className="mb-3 d-flex justify-content-start">
              <div className="me-3">Cliente NHCO* </div>
              <span className="mx-3">
                <input
                  type="radio"
                  id="si"
                  name="is_client"
                  onChange={handleOption}
                  value="si"
                />
                <label htmlFor="is_client" className="ms-1">
                  Sí
                </label>
              </span>

              <span className="mx-3">
                <input
                  type="radio"
                  id="no"
                  name="is_client"
                  onChange={handleOption}
                  value="no"
                />
                <label htmlFor="is_client" className="ms-1">
                  No
                </label>
              </span>
              {errors["is_client"] && (
                <p className="invalid">{errors["is_client"][0]}</p>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                onChange={handleChange}
                className="form-control"
                id="postcode"
                name="postcode"
                placeholder="Código postal"
                value={data.postcode}
                required
              />
              <label htmlFor="postcode">Código Postal*</label>
              {errors["postcode"] && <p className="invalid">{errors["postcode"][0]}</p>}
            </div>

            <div className="form-check text-start">
              <input
                className="form-check-input"
                onChange={handleCkeckChange}
                type="checkbox"
                checked={data.accepts}
                name="accepts"
                id="accepts"
                required
              />
              <label className="form-check-label" htmlFor="accepts">
                He leído y acepto{" "}
                <a
                  href="https://s3-eu-south-2.ionoscloud.com/cursos/nhco-registro/assets/Politica.pdf"
                  className="nav-links-red"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  la politica de privacidad
                </a>
              </label>
              {errors["accepts"] && (
                <p className="invalid">{errors["accepts"][0]}</p>
              )}
            </div>

            <div className="form-check text-start">
              <input
                className="form-check-input"
                onChange={handleCkeckChange}
                type="checkbox"
                checked={data.accepts_mayo}
                name="accepts_mayo"
                id="accepts_mayo"
              />
              <label className="form-check-label" htmlFor="accepts_mayo">
                Autorizo que Grupo Mayo me envíe comunicaciones sobre novedades
                y actividades formativas por medios electrónicos.
              </label>
              {errors["accepts_mayo"] && <p className="invalid">{errors["accepts_mayo"][0]}</p>}
            </div>

            <div className="form-check text-start">
              <input
                className="form-check-input"
                onChange={handleCkeckChange}
                type="checkbox"
                checked={data.accepts_chiesi}
                name="accepts_chiesi"
                id="accepts_chiesi"
              />
              <label className="form-check-label" htmlFor="accepts_chiesi">
                Autorizo que los datos facilitados sean cedidos a Chiesi España,
                S.A.U. con la finalidad de recibir información científica,
                promocional e institucional de Chiesi por medios electrónicos.
              </label>
              {errors["accepts_chiesi"] && (
                <p className="invalid">{errors["accepts_chiesi"][0]}</p>
              )}
            </div>

            {loader ? (
              <div className="d-flex justify-content-center my-3">
                <Loading />
              </div>
            ) : (
              <div className="d-flex justify-content-center mt-3">
                <button className="btn btn-primary mt-3">Enviar</button>
              </div>
            )}

            {succes === false && (
              <p className="error text-center">¡Ha ocurrido un error!</p>
            )}
          </form>
        </section>
      </div>
    </section>
  );
};
