export const Title = () => {
  return (
    <section className="container text-center">
      <img src="/img/nhco.svg" alt="nhco" />
      <div className="fs-5 my-2">Te invita al evento sobre</div>
      <h1>La mujer en el climaterio</h1>
      <h2 className="mx-5">Abordaje desde la farmacia</h2>
    </section>
  );
};
