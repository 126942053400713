import React from "react";

export const Programa = () => {
  return (
    <section className=" my-5 box__program p-5 ">
      <img
        src="/img/localizacion.svg"
        alt="localización"
        className="location"
      />
      <div className="text-center my-3">
        <div className="rosa fs-5 fw-bold">Loom Salamanca</div>
        <div className="mt-2">
          C/ Don Juan Ramón de la Cruz 38
          <br /> 28002 Madrid
        </div>
      </div>
      <hr className="my-4" />
      <article>
        <h3 className="rosa text-center mb-4">PROGRAMA</h3>
        <ul className="txt__programa">
          <li>
            Menopausia: una crisis evolutiva. Los resultados dependen de su
            abordaje
          </li>
          <li>El estradiol manda</li>
          <li>Principales manifestaciones</li>
          <li>Activos más destacados:</li>
          <div> - Aminoácidos</div>
          <div> - Otros</div>
          <li>Protocolo de recomendación By Asun Arias</li>
        </ul>
      </article>
      <hr className="my-4" />
      <p className="text-center rosa fw-bold">
        Al finalizar se servirá un cóctel
      </p>
    </section>
  );
};
