import { Main } from "../components/Main";
import { Title } from "../components/Title";

export const Home = () => {
  return (
    <>
      <Title />
      <Main />
    </>
  );
};
