import { Container, Row, Col } from "react-bootstrap";

import { FormTemplate } from "./FormTemplate";
import { Ponente } from "./Ponente";
import { Programa } from "./Programa";


export const Main = () => {
  return (
    <Container className="my-5">
      <Row>
        <Col lg={6} className="order-1 order-lg-0">
          <Ponente />
          <Programa />
        </Col>
        <Col lg={6} className="order-0 order-lg-1">
          <FormTemplate />
   
        </Col>
      </Row>
    </Container>
  );
};
