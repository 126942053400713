export const BoxTopRegister = () => {
  return (
    <section className="box__top_register text-center ">
      <div className="ball__date">
        <div className="day">19 </div>
        <div className="month">de Enero</div>
        <div className="year">2023</div>

        <div className="time mt-1">13:30 h</div>
      </div>
      <p className="fs-4 fw-light">¡Regístrate y reserva tu plaza!</p>
      <div className="fw-bold italic fs-5">Plazas limitadas</div>
    </section>
  );
};
