export const Header = () => {
  return (
    <header className="row d-flex flex-wrap justify-content-between align-items-start">
      <div className=" col-sm-8">
        <img src="/img/chiesi.png" alt="chiesi" className="img-fluid" />
      </div>
      <div className="col-12 col-sm-4 text-center text-sm-end ">
        <img
          src="/img/farmaceutico.svg"
          alt="elfarmacéutico"
          className="img-fluid pb-4 p-sm-4 text-end "
        />
      </div>
    </header>
  );
};
