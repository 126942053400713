import { useState } from "react";
import axios from "axios";

export const useForm = (init) => {
  const [data, setData] = useState(init);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };
  const handleCkeckChange = (e) => {
    setData({
      ...data,
      [e.currentTarget.name]: e.currentTarget.checked,
    });
  };
  const handleOption = (e) => {
    const { name, value } = e.currentTarget;

    setData({
      ...data,
      [name]: value,
    });
  };
  const post = async (url, data = {}, config = {}) => {
    const response = await axios.post(url, data, config);
    return response;
  };
  const clear = () => {
    setData(init);
  };

  return { data, handleChange, handleCkeckChange, handleOption, post, clear };
};
